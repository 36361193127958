import React, { useState, useRef, useContext } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import SaContext from "../Dashboard/SAContext";
import Row from "react-bootstrap/Row";
import "./ContactForm.css";
import SpinnerIcon from "./Spinner";

function ContactForm() {
  const formRef = useRef(null);
  const [validated, setValidated] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const { formSubmit, setFormSubmit } = useContext(SaContext);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    setSpinner(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      setSpinner(false);
    } else {
      setValidated(true);
      setSpinner(true);
      event.preventDefault();
      event.stopPropagation();

      const scriptUrl =
        "https://script.google.com/macros/s/AKfycby7Si6rRUo9rR9L7v3zna2v_57PWWdIMfWeHBwDBRv8BXPSiXkuDMBvB434mY-sNmVs/exec";
      fetch(scriptUrl, { method: "POST", body: new FormData(formRef.current) })
        .then((res) => {
          event.preventDefault();
          event.stopPropagation();
          setFormSubmit(true);
          event.target.reset();
          setValidated(false);
          setSpinner(false);
          console.log(res);
          console.log("SUCCESSFULLY SUBMITTED");
        })
        .catch((err) => console.log(err));
    }
  };

  function getCurrentDateTime() {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, "0"); // DD
    const month = now.toLocaleString("default", { month: "short" }); // MMM
    const year = now.getFullYear(); // YYYY

    let hours = now.getHours(); // 24-hour format
    const minutes = String(now.getMinutes()).padStart(2, "0"); // mm
    const seconds = String(now.getSeconds()).padStart(2, "0"); // ss

    const ampm = hours >= 12 ? "PM" : "AM"; // Determine AM/PM
    hours = hours % 12 || 12; // Convert to 12-hour format

    const formattedTime = `${day}-${month}-${year} ${String(hours).padStart(
      2,
      "0"
    )}:${minutes}:${seconds} ${ampm}`;
    return formattedTime;
  }

  return (
    <div className="contactFormDiv">
      <h3 id="contactFormTitle">Enquire Now</h3>
      <div style={{ fontSize: "14px", textAlign: "center", color: "#fff" }}>
        Please fill the form & we will reach you via call/WhatsApp!
      </div>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <Row className="mb-3">
          <Form.Group
            as={Col}
            md="4"
            controlId="validationCustom01"
            style={{ display: "none" }}
          >
            <Form.Label>Date</Form.Label>
            <Form.Control
              className="formField"
              type="text"
              name="Timestamp"
              value={getCurrentDateTime()}
            />
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustom01">
            <Form.Label>Name</Form.Label>
            <Form.Control
              className="formField"
              required
              type="text"
              placeholder="Your Full Name"
              name="Name"
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustom02">
            <Form.Label>Email</Form.Label>
            <Form.Control
              className="formField"
              required
              type="email"
              placeholder="abc@gmail.com"
              name="Email Address"
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustom02">
            <Form.Label>Contact Number</Form.Label>
            <Form.Control
              className="formField"
              required
              type="number"
              placeholder="Mobile (WhatsApp)"
              name="Contact Number"
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationCustom02">
            <Form.Label>Address</Form.Label>
            <Form.Control
              className="formField"
              required
              type="text"
              placeholder="Site location/nearby landmark"
              name="Address"
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom03">
            <Form.Label>Project Type</Form.Label>
            <Form.Select size="md" name="Type of Project" className="formField">
              <option>Residential (Home)</option>
              <option>Office</option>
              <option>Shop</option>
              <option>Cafe/Restaurant</option>
              <option>Hospitality</option>
              <option>Recreational</option>
              <option>Other</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please provide a valid city.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="validationCustom03">
            <Form.Label>Property Type</Form.Label>
            <Form.Select size="md" name="Property Type" className="formField">
              <option>Old</option>
              <option selected>New</option>
              <option>No possession yet</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please provide a valid city.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom03">
            <Form.Label>Area</Form.Label>
            <Form.Select size="md" name="Area" className="formField">
              <option>500 - 1000 sq.ft.</option>
              <option selected>1000 - 1500 sq.ft.</option>
              <option>1500 - 3000 sq.ft.</option>
              <option>3000 - 6000 sq.ft.</option>
              <option>&gt; 6000 sq.ft.</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please provide a valid city.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="validationCustom03">
            <Form.Label>BHK (if applicable)</Form.Label>
            <Form.Select size="md" name="BHK" className="formField">
              <option>1 BHK</option>
              <option>2 BHK</option>
              <option selected>3 BHK</option>
              <option>4 BHK</option>
              <option>5 BHK or more</option>
              <option>Other</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please provide a valid city.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>Services required from us</Form.Label>
            <Form.Select size="md" name="Services" className="formField">
              <option>Design</option>
              <option selected>
                All interior related end to end solutions
              </option>
              <option>Civil Services (Any making or breaking tilework)</option>
              <option>Other</option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>Detailed requirement or Queries</Form.Label>
            <Form.Control
              className="formField"
              type="text"
              placeholder="Please enter your queries"
              name="Requirements"
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Form.Group className="mb-3">
          <Form.Check
            label="Agree to T&C (optional)"
            feedback="You must agree before submitting."
            feedbackType="invalid"
          />
        </Form.Group>
        <Button
          type="submit"
          style={{
            backgroundColor: "var(--main-light-color)",
            color: "var(--main-dark-color)",
            border: "none",
          }}
        >
          Submit
        </Button>
        {spinner && <SpinnerIcon />}
      </Form>
    </div>
  );
}

export default ContactForm;
