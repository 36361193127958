import React, { useEffect, useState } from "react";
import "./GDImageViewer.css";

function GDImageViewer({ data }) {
  const [imgIds, setImgIds] = useState([]);

  const GOOGLE_API_KEY = data.gkey;
  const GOOGLE_DRIVE_FOLDER_URL = "https://www.googleapis.com/drive/v3/files";
  const GOOGLE_DRIVE_IMG_URL = "https://www.googleapis.com/drive/v3/files";

  const folderId = data.dirId; // Folder ID from Google Drive

  useEffect(() => {
    loadImages();
  }, []);

  const loadImages = async () => {
    const url = `${GOOGLE_DRIVE_FOLDER_URL}?q='${folderId}'+in+parents&key=${GOOGLE_API_KEY}&fields=files(id,name,mimeType)`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const jsonResp = await response.json();

      // Filter only valid image files
      const imageFiles = jsonResp.files.filter((file) =>
        file.mimeType.startsWith("image/")
      );
      setImgIds(imageFiles);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  return (
    <div>
      <h2>{data.header}</h2>
      <div className="image-gallery">
        {imgIds.length > 0 ? (
          imgIds.map((image, index) => (
            <img
              key={index}
              src={`${GOOGLE_DRIVE_IMG_URL}/${image.id}?alt=media&key=${GOOGLE_API_KEY}`}
              alt={image.name}
              className="gd-img"
              onError={(e) => {
                console.error(`Image failed to load: ${image.name}`, e);
                e.target.style.display = "none"; // Hide the image if it fails
              }}
              loading="lazy"
            />
          ))
        ) : (
          <p>Loading images...</p>
        )}
      </div>
    </div>
  );
}

export default GDImageViewer;
