import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import SaContext from "../Dashboard/SAContext";
import "./ProjectCard.css";

function ProjectCard({ cardDetails }) {
  const {
    srno,
    image,
    title,
    subTitle,
    pagetitle,
    clientName,
    siteLocation,
    siteType,
    siteSummary,
    googleDriveLink,
  } = cardDetails;

  const { currentProjet, setCurrentProject } = useContext(SaContext);
  const navigate = useNavigate();
  const showProject = () => {
    navigate("/project");
  };

  return (
    <div style={{ textDecoration: "none" }}>
      <div
        className="projectCardDiv"
        onClick={() => {
          showProject();
          setCurrentProject(srno);
        }}
      >
        <img id="projectImage" src={image} loading="lazy" />
        <div className="title">{title}</div>
        <div className="subTitle">{subTitle}</div>
        <div
          style={{
            textDecoration: "underline",
            color: "green",
            fontSize: "0.75em",
            fontWeight: "bold",
            marginBottom: "none",
          }}
        >
          Click for details
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
