import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./Testimonial.css";
import B1 from "../../assets/imgs/1.webp";
import B2 from "../../assets/imgs/2.webp";
import B3 from "../../assets/imgs/5.webp";

function Testimonial() {
  return (
    <>
      <Carousel fade style={{ marginBottom: "1rem", borderRadius: "0.5em" }}>
        <Carousel.Item interval={5000}>
          <img
            className="d-block w-100"
            style={{
              height: "35vh",
              objectFit: "cover",
              filter: "brightness(35%)",
              borderRadius: "0.5em",
            }}
            src={B1}
            alt="First slide"
            loading="lazy"
          />
          <Carousel.Caption>
            <p className="testimonials">
              Aishwarya is extremely hard working, patient and dedicated
              professional who will turn your designs into reality in no time.
              Her ' CAN DO' attitude and the quality of work is most impressive.
              I would highly recommend her to others for their upcoming
              projects. Wish you all the luck. Thanks!!!!
            </p>
            <p>-&nbsp;Shradha Raina</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100"
            style={{
              height: "35vh",
              filter: "brightness(35%)",
              objectFit: "cover",
              borderRadius: "0.5em",
            }}
            src={B2}
            loading="lazy"
            alt="Second slide"
          />
          <Carousel.Caption>
            <p className="testimonials">
              It was our best decision to hire them for interior design of our
              3bhk home. Extremely talented and hard working team !! Good
              Listener !! Considered all of our requirement !! Trust worthy !!
              They did end to end work for our home within our budget and in
              commited time . They took our space to the next level .. Thanks
              alot for all your efforts .. I Highly recommend them ..⭐⭐⭐⭐⭐
            </p>
            <p>-&nbsp;Aman deep</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <img
            className="d-block w-100"
            style={{
              height: "35vh",
              filter: "brightness(35%)",
              objectFit: "cover",
              borderRadius: "0.5em",
            }}
            src={B3}
            alt="Third slide"
            loading="lazy"
          />
          <Carousel.Caption>
            <p style={{ fontSize: "0.8rem" }}>
              We had a wonderful experience working with Aishwarya and Team as
              our home designer. Their creativity, attention to details were
              impressive. They helped us to transform our ideas into a
              beautiful, functional living space. Their professionalism and
              dedication to client satisfaction made the entire process
              enjoyable. I highly recommend @sa_interior_scapes to anyone
              seeking a talented and passionate home designer. Thank you,
              @sa_interior_scapes, for helping us to create our dream home!
            </p>
            <p>-&nbsp;Neeta Bhatane</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
}

export default Testimonial;
